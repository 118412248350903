@import url("https://use.typekit.net/nca8bjl.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* <link rel="stylesheet" href="https://use.typekit.net/nca8bjl.css"></link> */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bressay {
  font-family: bressay-devanagari, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.samantha {
  font-family: samantha-upright,sans-serif;
font-weight: 400;
font-style: normal;
}

.clarendon {
  font-family: clarendon-urw, serif;
  font-weight: 400;
  font-style: normal;
}

.obvia {
  font-family: obvia, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.hoss-round {
  font-family: hoss-round, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto {
  font-family: roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.number-five {
  font-family: number-five-smooth, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.gioviale {
  font-family: gioviale, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.spumante {
  font-family: spumante, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.tangier {
  font-family: tangier, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.funkydori {
  font-family: funkydori, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.interstate {
  font-family: interstate,sans-serif;
font-weight: 500;
font-style: normal;
}

.amador {
  font-family: amador, sans-serif;
  font-weight: 400;
  font-style: normal;
}